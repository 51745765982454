<template>
  <div class="main-container" v-fade-in>
    <div class="content-container">
      <div class="contact-box">
        <span>Lukáš Šimandl</span>
        <span>tel. 604 403 564</span>
        <span>e-mail: <a href="mailto:videojinak@centrum.cz">videojinak@centrum.cz</a></span>
      </div>
      <div class="icon-wrapper">
        <a href="https://www.youtube.com/@lukassvatby/featured" target="_blank" class="icon youtube">
          <i class="fab fa-youtube"></i>
        </a>
        <a href="https://www.facebook.com/@Videojinak" target="_blank" class="icon facebook">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="https://www.instagram.com/videojinak_lukas_simandl/" target="_blank" class="icon instagram">
          <i class="fab fa-instagram"></i>
        </a>
      </div>
      <div class="form-wrapper">
        <div class="form1">
          <h2>Kontaktní formulář pro svatebčany</h2>
          <form autocomplete="on" @submit.prevent="submitWeddingForm">
            <v-text-field
              v-model="formDataWedding.name"
              :rules="[nameRule]"
              label="Jméno ženicha a nevěsty"
              autocomplete="name"
            ></v-text-field>
            <v-text-field
              v-model="formDataWedding.phone"
              :rules="[phoneRule]"
              label="Telefonní číslo"
              autocomplete="tel"
            ></v-text-field>
            <v-text-field
              v-model="formDataWedding.email"
              :rules="[emailRule]"
              label="E-mail"
              autocomplete="email"
            ></v-text-field>
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              location="right center"
              class="custom-datepicker-position"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formDataWedding.date"
                  :rules="[dateRule]"
                  :value="formatDate(formDataWedding.date)"
                  placeholder="DD.MM.RRRR"
                  label="Termín svatby"
                  readonly
                  @click="dateMenu = true"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="formDataWedding.date"
                locale="cs"
                @input="dateMenu = false"
                no-title
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-model="formDataWedding.place"
              :rules="[whereRule]"
              label="Kde se svatba bude konat?"
            ></v-text-field>
            <v-text-field
              v-model="formDataWedding.how"
              :rules="[whatRule]"
              label="Jak jste se o nás dozvěděli?"
            ></v-text-field>
            <v-text-field
              v-model="formDataWedding.about"
              :rules="[aboutRule]"
              label="Něco mi o sobě napište"
            ></v-text-field>
            <div class="submit-btn-wrapper">
              <v-btn type="submit">Odeslat</v-btn>
              <v-btn @click="resetWeddingForm" type="button">Smazat data</v-btn>
            </div>
          </form>
        </div>
        <div class="form2">
          <h2>Kontaktní formulář pro firmy</h2>
          <form autocomplete="on" @submit.prevent="submitCompanyForm">
            <v-text-field v-model="formDataCompany.name2" :rules="[nameRule]" label="Jméno"></v-text-field>
            <v-text-field v-model="formDataCompany.email2" :rules="[emailRule]" label="Email"></v-text-field>
            <v-text-field v-model="formDataCompany.message2" :rules="[aboutRule]" label="Zpráva"></v-text-field>
            <div class="submit-btn-wrapper">
              <v-btn type="submit">Odeslat</v-btn>
              <v-btn @click="resetCompanyForm" type="button">Smazat data</v-btn>
            </div>
          </form>
        </div>
      </div>
      <v-snackbar v-model="snackbar" :timeout="3000" color="green" absolute top left right>
        {{ successMessage }}
      </v-snackbar>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { db } from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import axios from "axios";
import qs from "qs";

const dateMenu = ref(false);
const formatDate = (date) => {
  if (!(date instanceof Date)) return date;
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

// Validační pravidla pro inputy
const nameRule = (value) => value?.length >= 2 || "Jméno musí být delší než 2 znaky.";
const whereRule = (value) => value?.length >= 5 || "Text musí být delší než 5 znaků.";
const whatRule = (value) => value?.length >= 2 || "Text musí být delší než 2 znaků.";
const aboutRule = (value) => value?.length >= 5 || "Text musí být delší než 5 znaků.";
const phoneRule = (value) => /^[0-9-]{9,}$/.test(value) || "Číslo musí mít minimálně 9 číslic";
const emailRule = (value) => /^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(value) || "Email musí být platný";

const snackbar = ref(false);

const formDataWedding = ref({
  name: "",
  phone: "",
  email: "",
  date: null, // Nastavení na null místo řetězce
  place: "",
  how: "",
  about: "",
  isSent: false,
});

const formDataCompany = ref({
  name2: "",
  email2: "",
  message2: "",
  isSent: false,
});

const successMessage = ref("");

// Funkce pro odeslání emailu
onMounted(() => {
  setInterval(() => {
    sendEmailRequest();
  }, 120000); // Každé 2 minuty
});

const sendEmailRequest = () => {
  fetch("https://www.videojinak.cz/php-scripts/send_email.php")
    .then((response) => response.text())
    .then((data) => {
      console.log("Response from PHP script:", data);
    })
    .catch((error) => {
      console.error("Error sending request:", error);
    });
};

// Odeslání formuláře pro svatebčany
const submitWeddingForm = async () => {
  try {
    if (formDataWedding.value.isSent) {
      console.log("Tato data byla již odeslána.");
      return;
    }

    await addDoc(collection(db, "contact"), {
      type: "wedding",
      ...formDataWedding.value,
      timestamp: serverTimestamp(),
    });

    const response = await axios.post(
      "https://videojinak.cz/php-scripts/send_email.php",
      qs.stringify(formDataWedding.value),
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    );
    console.log("Odpověď z PHP: ", response.data);

    successMessage.value = response.data;
    snackbar.value = true; // Zobrazí snackbar
    formDataWedding.value.isSent = true;
    resetWeddingForm();
  } catch (error) {
    console.error("Chyba při odesílání svatebního formuláře: ", error);
    alert("Nastala chyba při odesílání dat.");
  }
};

// Odeslání formuláře pro firmy
const submitCompanyForm = async () => {
  try {
    if (formDataCompany.value.isSent) {
      console.log("Tato data byla již odeslána.");
      return;
    }

    await addDoc(collection(db, "contact"), {
      type: "company",
      ...formDataCompany.value,
      timestamp: serverTimestamp(),
    });

    successMessage.value = "Formulář byl odeslán";
    snackbar.value = true; // Zobrazí snackbar pro firmy
    formDataCompany.value.isSent = true;
    resetCompanyForm();
  } catch (error) {
    console.error("Chyba při odesílání formuláře pro firmy: ", error);
    alert("Nastala chyba při odesílání dat.");
  }
};

// Reset formuláře pro svatebčany
const resetWeddingForm = () => {
  formDataWedding.value = {
    name: "",
    phone: "",
    email: "",
    date: null, // Nastavení na null místo řetězce
    place: "",
    how: "",
    about: "",
    isSent: false,
  };
};

// Reset formuláře pro firmy
const resetCompanyForm = () => {
  formDataCompany.value = {
    name2: "",
    email2: "",
    message2: "",
    isSent: false,
  };
};
</script>
<style lang="scss" scoped>
.custom-datepicker-position {
  top: 63% !important;
  left: 10% !important;
  @media (min-width: 992px) {
    top: 50% !important;
    left: 50% !important;
  }
}
.main-container {
  padding: 20px;
  margin: auto;
}

.content-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;

  .contact-box {
    display: flex;
    flex-direction: column;
    gap: 5px;
    a {
      color: #000;
    }
  }
  .form-wrapper {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    .form1,
    .form2 {
      flex: 1 1 calc(100% - 15px);
      max-width: calc(100% - 15px);
      box-sizing: border-box;

      @media (min-width: 992px) {
        flex: 1 1 calc(50% - 15px);
        max-width: calc(50% - 15px);
      }
      @media (min-width: 992px) {
        flex: 1 1 calc(50% - 15px);
        max-width: calc(50% - 15px);
        box-sizing: border-box;
      }
      .submit-btn-wrapper {
        gap: 10px;
        display: flex;
      }
    }
  }
}

.success-message {
  color: green;
  margin-top: 20px;
}

.icon-wrapper {
  display: flex;
  gap: 10px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 24px;
  border-radius: 8px;
  text-decoration: none;
}

.icon.youtube {
  background-color: #ff0000;
}

.icon.facebook {
  background-color: #3b5998;
}

.icon.instagram {
  background-color: #e1306c;
}
</style>
